@use './variables' as variables;
@use './colors' as colors;

.flex-row-center {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flex-grow-1 {
  min-width: 0;
}

.pt-6 {
  padding-top: 4rem;
}

.h-0 {
  height: 0;
}

.w-0 {
  width: 0;
}

.mw-50 {
  max-width: 50%;
}

.right-0 {
  right: 0;
}

.content-center {
  display: flex;

  flex-wrap: wrap;

  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.vertical-align-top {
  vertical-align: top;
}

.vertical-align-super {
  vertical-align: super;
}

.vertical-align-middle {
  vertical-align: middle;
}

.d-none-if-empty {
  &:empty {
    display: none;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.hover-highlight {
  &:hover {
    background: colors.$highlighted-background-color !important;
  }
}

.hover-visible {
  opacity: 0;
  transition: opacity 0.25s ease-in-out;

  &:hover {
    opacity: 1;
  }
}

.hover-container {
  .hover-display-none {

  }

  .hover-display-block {
    display: none;
  }

  &:hover {
    .hover-display-none {
      display: none;
    }

    .hover-display-block {
      display: block;
    }

    .hover-visible {
      opacity: 1;
    }
  }
}

@media (max-width: 500px) {
  .hover-container {
    .hover-display-block {
      display: block;
    }
  }
}

.hover-outline {
  transition: border 0.25s ease-in-out;

  &:hover {
    border-color: colors.$accent-color;
  }
}

.border-rounded {
  border-radius: variables.$border-radius;
}

.drop-shadow {
  box-shadow: 0 3px 1px -2px #0003, 0 2px 2px 0 #00000024, 0 1px 5px 0 #0000001f;
}

.bg-background {
  background-color: colors.$background-color;
}

.bg-accent {
  background-color: colors.$accent-color;

  color: colors.$text-on-accent-color;
}

.bg-skeleton {
  background-color: colors.$skeleton-color;
}

.border-none {
  border: none !important;
}

.border-accent {
  border: 1px solid colors.$accent-color !important;
}

.border-colored {
  border: 1px solid colors.$border-color;
}

.border-bottom-colored {
  border-bottom: 1px solid colors.$border-color;
}

.border-dark {
  border: 1px solid colors.$border-dark-color !important;
}

.border-white {
  border: 1px solid #fff !important;
}

.z-index-1000 {
  z-index: 1000;
}

button.button-google {
  background-color: #3899ff !important;
  color: #fff !important;

  &[disabled] {
    background-color: rgba(0, 0, 0, 0.12) !important;
    color: rgba(0, 0, 0, 0.38) !important;
  }
}
