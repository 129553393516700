@use '../colors' as colors;
@use '@angular/material' as mat;

@include mat.core();

$primary-palette-colors: (
  50: #fbfbfb,
  100: #f6f6f6,
  200: #f2f2f2,
  300: #e8e8e8,
  400: #c6c6c6,
  500: #a8a8a8,
  600: #7e7e7e,
  700: #6a6a6a,
  800: colors.$primary-color,
  900: #282828,
  contrast: (
    50: colors.$dark-primary-text-color,
    100: colors.$dark-primary-text-color,
    200: colors.$dark-primary-text-color,
    300: colors.$dark-primary-text-color,
    400: colors.$dark-primary-text-color,
    500: colors.$dark-primary-text-color,
    600: colors.$light-primary-text-color,
    700: colors.$light-primary-text-color,
    800: colors.$light-primary-text-color,
    900: colors.$light-primary-text-color,
  )
);

$accent-palette-colors: (
  50: #e3f1ee,
  100: #bcded4,
  200: #94c9b9,
  300: #70b39f,
  400: #5ca28c,
  500: #51927b,
  600: colors.$accent-color,
  700: #447562,
  800: #3d6555,
  900: #31493d,
  contrast: (
    50: colors.$dark-primary-text-color,
    100: colors.$light-primary-text-color,
    200: colors.$light-primary-text-color,
    300: colors.$light-primary-text-color,
    400: colors.$light-primary-text-color,
    500: colors.$light-primary-text-color,
    600: colors.$light-primary-text-color,
    700: colors.$light-primary-text-color,
    800: colors.$light-primary-text-color,
    900: colors.$light-primary-text-color,
  )
);

$primary-palette: mat.define-palette($primary-palette-colors, 800);
$accent-palette: mat.define-palette($accent-palette-colors, 600);

$ohana-typography: mat.define-typography-config(
  $body-1: mat.define-typography-level($font-size: 14px, $letter-spacing: 0rem),
  $body-2: mat.define-typography-level($font-size: 14px, $letter-spacing: 0rem),
  $button: mat.define-typography-level($font-size: 14px, $font-weight: 500, $letter-spacing: 0rem),
);

$ohana-theme: mat.define-light-theme(
    (
      color: (
        primary: $primary-palette,
        accent: $accent-palette,
      ),
      typography: $ohana-typography,
      density: 0,
    )
);

@include mat.all-component-typographies($ohana-typography);

@include mat.core-theme($ohana-theme);

@include mat.card-theme($ohana-theme);
@include mat.progress-bar-theme($ohana-theme);
@include mat.tooltip-theme($ohana-theme);
@include mat.form-field-theme($ohana-theme);
@include mat.input-theme($ohana-theme);
@include mat.select-theme($ohana-theme);
@include mat.dialog-theme($ohana-theme);
@include mat.chips-theme($ohana-theme);
@include mat.slide-toggle-theme($ohana-theme);
@include mat.radio-theme($ohana-theme);
@include mat.slider-theme($ohana-theme);
@include mat.menu-theme($ohana-theme);
@include mat.list-theme($ohana-theme);
@include mat.tabs-theme($ohana-theme);
@include mat.checkbox-theme($ohana-theme);
@include mat.button-theme($ohana-theme);
@include mat.icon-button-theme($ohana-theme);
@include mat.progress-spinner-theme($ohana-theme);
@include mat.button-toggle-theme($ohana-theme);
@include mat.datepicker-theme($ohana-theme);
@include mat.expansion-theme($ohana-theme);
@include mat.table-theme($ohana-theme);

@include mat.button-density(0);
@include mat.form-field-density(-1);
@include mat.input-density(-1);

.mat-mdc-raised-button {
  background-color: colors.$skeleton-color;
}

.mdc-icon-button fa-icon svg {
  height: 1em;
}

.mat-mdc-form-field {
  width: 100%;
}

.mdc-radio {
  padding: 5px !important;
}

.mat-mdc-standard-chip {
  font-size: 11px;
  padding: 2px 5px !important;
  height: 24px !important;
  text-transform: uppercase;

  .mdc-evolution-chip__text-label {
    color: inherit !important;
  }

  .mdc-evolution-chip__action--primary {
    padding-left: 10px;
  }
}


.mat-mdc-form-field.mat-form-field-appearance-fill {
  .mat-mdc-text-field-wrapper {
    background-color: transparent !important;
  }

  &.mat-focused {
    .mat-mdc-form-field-focus-overlay {
      opacity: 0.05 !important;
    }
  }

  .mat-mdc-form-field-infix {
    min-height: 40px !important;
    padding-top: 18px !important;
  }

  .mat-mdc-form-field-icon-prefix, .mat-mdc-form-field-icon-suffix {
    padding: 10px 4px 0 0;
  }

  &:has([matInput]) {
    .mat-mdc-floating-label {
      top: 24px !important;

      &.mdc-floating-label--float-above {
        top: 20px !important;
      }
    }
  }

  &:has(mat-select) {
    .mat-mdc-floating-label {
      top: 23px !important;

      &.mdc-floating-label--float-above {
        top: 23px !important;
      }
    }

    .mat-mdc-select-arrow-wrapper {
      padding-top: 10px;
      height: 20px;
    }
  }
}

.mat-mdc-dialog-container {
  max-height: 95vh !important;

  .mat-mdc-dialog-surface {
    overflow-y: scroll;
  }
}

.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
  0 8px 10px 1px rgba(0, 0, 0, 0.14),
  0 3px 14px 2px rgba(0, 0, 0, 0.12);

  pointer-events: auto !important;

  .cdk-drag-handle {
    cursor: grabbing;
  }

  .cdk-drag-hide {
    display: none;
  }
}

.cdk-drag-handle {
  cursor: grab;
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drop-list-dragging {
  .mat-row:not(.cdk-drag-placeholder) {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  }
}

.mat-sort-header-content {
  user-select: none;
}

.hidden-radio {
  .mdc-list-item {
    padding-right: 16px !important;

    .mdc-list-item__end {
      display: none;
    }
  }
}

.mdc-list-item--with-leading-checkbox .mdc-list-item__start {
  margin-left: 8px !important;
  margin-right: 8px !important;
}
